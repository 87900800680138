let scrollpos = window.scrollY;
const header = document.querySelector("header");
const headerHeight = header.offsetHeight;
const addClassOnScroll = () => header.classList.add("sticky");
const removeClassOnScroll = () => header.classList.remove("sticky");

// get a reference to our predefined button
var scrollToTopBtn = document.querySelector(".back-to-top");

window.addEventListener("scroll", function () {
	scrollpos = window.scrollY;

	if (scrollpos >= headerHeight) {
		addClassOnScroll();
	} else {
		removeClassOnScroll();
	}

	if (scrollpos >= 300) {
		scrollToTopBtn.style.display = "flex";
	} else {
		scrollToTopBtn.style.display = "none";
	}
});

scrollToTopBtn.addEventListener("click", scrollToTop);

function scrollToTop() {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
}

function windowSize() {
	size = window.innerWidth;
	if (size >= 991) {
		document.body.classList.remove("nav-is-toggled");
		hamburger.classList.remove("active");
	}
}

const navExpand = [].slice.call(document.querySelectorAll(".nav-expand"));

navExpand.forEach((item) => {
	item.querySelector(".nav-link").addEventListener("click", () => {
		item.classList.toggle("active");
		document.querySelector(".nav-main").classList.toggle("active-submenu");
	});
});

window.onload = function () {
	windowSize();
};

// window.onresize = function() {
//     windowSize();
// };

const hamburger = document.getElementById("navToggleIcon");
hamburger.addEventListener("click", function () {
	hamburger.classList.toggle("active");
	document.body.classList.toggle("nav-is-toggled");
});

// Technique / Creation Slider
var swiper = new Swiper(".swiper-slider--common", {
	slidesPerView: 1.2,
	spaceBetween: 30,
	// loop: false,
	rewind: true,
	speed: 800,
	// autoplay: {
	//     delay: 1500,
	// },
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},

	breakpoints: {
		640: {
			slidesPerView: 1.2,
		},
		768: {
			slidesPerView: 1.5,
			centeredSlides: false,
		},
		992: {
			slidesPerView: 2.1,
			centeredSlides: false,
		},
		1400: {
			slidesPerView: 3.2,
			centeredSlides: false,
		},
		1921: {
			slidesPerView: 2.85,
			centeredSlides: false,
		},
	},
});

// Example Slider
var swiper = new Swiper(".examples-slider", {
	slidesPerView: 1.2,
	spaceBetween: 30,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},

	breakpoints: {
		640: {
			slidesPerView: 1.2,
		},
		768: {
			spaceBetween: 30,
			slidesPerView: 1.2,
			grid: {
				rows: 1,
				fill: "row",
			},
		},
		992: {
			slidesPerView: 2,
			spaceBetween: 50,
			grid: {
				rows: 2,
				fill: "row",
			},
		},
	},
});

// Team Slider
var swiper = new Swiper(".team-member--grid", {
	slidesPerView: 3,
	spaceBetween: 30,
	rewind: true,
	speed: 800,

	breakpoints: {
		0: {
			slidesPerView: 1.2,
		},
		768: {
			slidesPerView: 2.2,
			centeredSlides: false,
		},
		1200: {
			slidesPerView: 3,
			centeredSlides: false,
		}
	},
});